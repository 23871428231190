p {
	font-family: 'Recoleta', sans-serif;
	font-size: calc(16px + (25 - 16) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1.5545;
	//font-size: 2.1rem;
	//line-height: 3.2rem;
	color: white;

}

.desc {
	margin:0;
	padding:0;
}

.statement {
	p {
		font-family: 'Recoleta', sans-serif;
		

		font-size: calc(22px + (38 - 22) * ((100vw - 300px) / (1900 - 300)));
		line-height: 1.4545;
	}
}
