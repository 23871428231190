.porto {
  background-color: #1dbed7;
}

.ttp {
  background-color: #004B8D;
}

.boeing {
  background-color: #209cf2;


}

.bbciau {
  background-color: #6520f2;
}

.aat {
  background-color: #20f2b4;
}


.pocko {
  background-color: #292f3a;

}

.linearpartners {
  background-color: #FF6A97;
}



#project {
  &__header {
    padding: 20vh 0 40px;
  }

  &__hero {
    &.container {
      @include media-breakpoint-down(sm) {
        overflow: hidden;
        width: 100% !important;
        max-width: 100% !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
      } 
    }

    .row {
      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }
  }
}

.disciplines {
  margin: 0;
  padding: 0;
  list-style: none;
  animation: fade-in 1s;

  @include media-breakpoint-down(sm) {
    margin-top:10px;
  }

  li {
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid white;
    border-radius: 5px;

    @include media-breakpoint-down(sm) {
      font-size:1.4rem;
      padding: 4px 8px;
    }
  }

  
}



.screen-view {
  width:100%;
  border-radius: 10px;
  padding:5vw;
  text-align: center;

  @include media-breakpoint-down(lg) {
    padding: 45px;
  }

  @include media-breakpoint-down(md) {
    padding: 20px;
  }

  @include media-breakpoint-down(md) {
    padding: 8px; 
    border-radius: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 8px; 
    border-radius: 3px;
  }


  &--lg {
    @include media-breakpoint-down(sm) {
      padding: 0;
      
    }
  }


  &--sm {
    background: red;
    padding: 4vw;

    @include media-breakpoint-down(xl) {
      padding: 3vw;
    }

    @include media-breakpoint-down(md) {
      padding: 10px; 
      margin: 0 auto 20px;
      max-width: 400px; 
    }

    @include media-breakpoint-down(sm) {
      padding: 8px;
      
    }
  }

  img {
    max-width: 80vw;
    border-radius: 10px;

    @include media-breakpoint-down(xl) {
     max-width:100%;
    }

    @include media-breakpoint-down(md) {
      border-radius: 5px;
    }

    @include media-breakpoint-down(sm) {
      border-radius: 3px;
    }
  }
}

.porto-screen-view {
  background: rgb(27,174,196);
  background: linear-gradient(0deg, rgba(27,174,196,1) 0%, rgba(26,166,187,1) 90%);
}

.ttp-screen-view {
  background: linear-gradient(0deg, rgba(0,66,123,1) 0%, rgba(0,64,117,1) 90%);
}

.boeing-screen-view {
  background: linear-gradient(0deg, rgba(30,149,232,1) 0%, rgba(28,144,225,1) 90%);
}

.bbciau-screen-view {
  background: linear-gradient(0deg, rgba(94,30,225,1) 0%, rgba(92,29,220,1) 90%);
}

.aat-screen-view {
  background: linear-gradient(0deg, rgba(31,229,171,1) 0%, rgba(31,223,166,1) 90%);
}

.pocko-screen-view {
  background: linear-gradient(0deg, rgba(35,40,49,1) 0%, rgba(28,33,40,1) 90%);
}

.linearpartners-screen-view {
  background: linear-gradient(0deg, rgba(240,101,143,1) 0%, rgba(235,100,140,1) 90%);
}