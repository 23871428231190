a {
	color: white;
	text-decoration: none;
	transition-duration: 0.3s;
  transition-property: all;
	
	&:visited {
		color: white;
		text-decoration: none;
	}

	&:hover,
	&:active {
		color:black;
	}
}

.btn-std {
	display:inline-block;
	background: white;
	color: $shaft;
	padding: 15px 20px;
	border-radius: 10px;

	@include media-breakpoint-down(sm) {
	  font-size: 1.5rem;
		padding: 8px 20px;
	}



	&:visited {
		color: $shaft;
	}

	&:hover,
	&:active {
		background: $shaft;
		color: white;
	}
}

.link-std {
	color: white;
	padding-bottom: 1px;
	border-bottom: 1px solid transparent;

	&:visited {
		color: white;
	}

	&:hover,
	&:active {
		border-color: white;
	}
}

.link {
	display: inline-block;
	padding: 25vh 0 10vh;
	font-size: calc(40px + (100 - 40) * ((100vw - 300px) / (1900 - 300)));
  line-height: 1;
  color: white;
  text-align: center;
  padding-bottom: 1px;
	border-bottom: 1px solid transparent;

  @include media-breakpoint-down(sm) {
   padding: 10vh 0 2vh;
  }

  &:visited {
    color: white;
  }

  &:hover,
  &:active {
  	color: white;
    border-color: white;
  }
}