.logo-wrap {
  display: flex;
  will-change: transform;
  animation: slideRight 80s linear infinite;
}

@keyframes slideRight {
    0% {
        transform: translate3d(-100%,0,0)
    }

    to {
        transform: translateZ(0)
    }
}

.logo-item {
  display: inline-block;
  border:1px solid white;
  border-radius: 10px;
  width: 160px;
  height: 160px;
  overflow: hidden;
  margin: 0 10px;

    @include media-breakpoint-down(md) {
        width: 120px;
        height: 120px;
    }
}