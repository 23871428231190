html {
	box-sizing: border-box;
	min-height:100%;
    height:100%;
    font-size:62.5%;
	line-height:62.5%;
	margin:0;
    padding:0;
}

*,
*:before,
*:after { 
	box-sizing: inherit;
}

@include scrollbars(.5em, slategray);

body {
	font-size: 1.6rem;
	line-height: 2.2rem;
	font-family: 'Recoleta', sans-serif;
	font-weight: 500;
	font-style: normal;
	color: white;
	margin: 0;
	padding: 0;
	background: #09158b;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;

}

::selection {
 	background: #ff14bf;
  	color: #FFF;
}
::-moz-selection {
  	background: #ff14bf;
	color: #FFF;
}

main {
		background-image: linear-gradient(#09158b 0%,#3508e7 10%,#4a08e2 20%,#5c08e2 30%,#650adb 40%,#6f0ed8 50%,#7318c3 60%,#7220ac 70%,#6c2697 80%,#af1dbf 90%,#501cca 100%);
		position: relative;
		width: 100%;
}

body:hover > #cursor {
  opacity: 1;
}

#cursor {
  height: 16px;
  width: 16px;
  background-color: white;
  border-radius: 20px;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 500ms ease;
  mix-blend-mode: difference;
  display: grid;
  place-items: center;

  @include media-breakpoint-down(md) {
    display:none;
  }
}

#cursor:not([data-type=""]) > #cursor-icon {
  opacity: 1;

  @include media-breakpoint-down(md) {
    display:none;
  }
}

#cursor-icon {
	font-family: Arial;
	position:relative;
	font-size:2.5px;
	line-height:3px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	display:block;
  	color:white;
  	opacity: 0;
  	transition: opacity 400ms ease;

  	@include media-breakpoint-down(md) {
    	display:none;
  	}
}


.portfolio-icon::after {
  content: "";
  background-color: transparent;
  background-image: url(../resources/images/eye.svg);
  background-size: 6px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width:10px;
  height: 10px;
  font-style: normal;

  @include media-breakpoint-down(md) {
    display:none;
  }
}

hr {

	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

img {
	height: auto; 
	max-width: 100%; 
	width:100%;
}

ol {
	padding-bottom:30px;
}

figure {
	margin:0;
	padding:0;
}

.o-embed-res { 
	position: relative; 
	padding-bottom: 56.25%; 
	height: 0; 
	overflow: hidden; 
	max-width: 100%; 

	iframe,
	object,
	embed,
	video {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%;
	}
}

video {
	max-width: 100%;
	display: block;
}

.o-curve {
	border-radius: 10px;

	@include media-breakpoint-down(md) {
      border-radius: 5px;
    }

    @include media-breakpoint-down(sm) {
      border-radius: 3px;
    }
}

.o-img-container {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    height: 100%;

    @include media-breakpoint-down(md) {
      border-radius: 5px;
    }

    @include media-breakpoint-down(sm) {
      border-radius: 3px;
    }

    &__bg {
	    width:100%;
	    height:100%;
	    background: rgba(255,255,255,1);
	    position:absolute;
	    top:0;
	    left:0;
	    opacity:0;
	    z-index:1;
	    border-radius: 10px;
	    transition-duration: 0.3s;
	    transition-property: all;
	    transition-delay: 0.2s;

	    @include media-breakpoint-down(md) {
	      border-radius: 5px;
	    }

	    @include media-breakpoint-down(sm) {
	      border-radius: 3px;
	    }
	  }

    img {
        width: 100%;
        opacity: 0;
        transition-duration: 0.3s;
        transition-property: all;
        transition-delay:0.5s;
    }
  
    &:after {
        content: "";
        position: absolute;
        width: 0%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255,255,255,1);
        border-radius: 10px;

        @include media-breakpoint-down(md) {
	      border-radius: 5px;
	    }

	    @include media-breakpoint-down(sm) {
	      border-radius: 3px;
	    }
    }

    &.o-img-deep {
    	&:after {
    		background: $deep;
    	}
    }

    &.o-img-congress {
    	&:after {
    		background: $congress;
    	}
    }

    &.o-img-end {
    	&:after {
    		background: $end;
    	}
    }

    &.o-img-martini {
    	&:after {
    		background: $martini;
    	}
    }

    &.o-img-haze {
    	&:after {
    		background: $haze;
    	}
    }

    &.o-img-black {
    	&:after {
    		background: black;
    	}
    }

    &.o-img-pink {
    	&:after {
    		background: $pink;
    	}
    }

    &.o-image-reveal {
        img {
            opacity:1;
            transition-duration: 0.3s;
            transition-property: all;
            transition-delay:600ms;
        }

        &:after {
            animation-name: reveal;
            animation-duration: 900ms;
            animation-timing-function: cubic-bezier(0.6, 0, 0.3, 1);
        }
    }
}

@keyframes reveal {
    0% {
        width:0%;
        left: 0;
    }
    49% {
        width:100%;
        left:0;
    }
    50% {
        width:100%;
        right:0;
        left: auto;
    }
    100% {
        width: 0%;
        right:0;
        left: auto;
    }
}

//Colours

.o-cl-white {
	&,
	p {
		color: white;
	}

	a {
		color: white;

		&:visited {
			color: white;
		}
	}
}

.o-cl-dark {
	&,
	p {
		color: $shaft;
	}

	a {
		color: $shaft;

		&:visited {
			color: $shaft;
		}
	}
}
//Backgrounds

.o-bg-white {
	background: white;
}

.o-bg-blue {
	background: $blue;
}

//Padding
.o-pd-top-ss {
	padding-top:5px;
}

.o-pd-top-xs {
	padding-top:20px;
}

.o-pd-top-sm {
	padding-top:40px;

}

.o-pd-top-md {
	padding-top: 50px;
}

.o-pd-top-lg {
	padding-top:150px;

	@include media-breakpoint-down(md) {
		padding-top:100px;
	}

	@include media-breakpoint-down(sm) {
		padding-top:80px;
	}
}

.o-pd-top-15-lg {
	@include media-breakpoint-up(lg) {
		padding-top:150px;
	}
}

.o-pd-top-xl {
	padding-top: 200px;

	@include media-breakpoint-down(md) {
		padding-top:65px;
	}
}

.o-pd-btm-ss {
	padding-bottom:5px;
}

.o-pd-btm-xs {
	padding-bottom:20px;
}

.o-pd-btm-sm {
	padding-bottom:40px;

}

.o-pd-btm-md {
	padding-bottom: 50px;
}

.o-pd-btm-lg {
	padding-bottom: 150px;
}

.o-pd-btm-xl {
	padding-bottom: 200px;

	@include media-breakpoint-down(md) {
		padding-bottom:65px;
	}
}

.p-l-50 {
	padding-left:50px;
}

.p-r-50 {
	padding-right:50px;
}

//Margin
.o-mg-none {
	margin:0;
}

.o-mg-top-none {
	margin-top:0px;
}

.o-mg-top-ss {
	margin-top:5px;
}

.o-mg-top-xs {
	margin-top:20px;
}

.o-mg-top-sm {
	margin-top:40px;
}

.o-mg-top-md {
	margin-top: 60px;
}

.o-mg-top-lg {
	margin-top:120px;
}


.o-mg-btm-ss {
	margin-bottom:8px;
}

.o-mg-btm-xs {
	margin-bottom:20px;
}

.o-mg-btm-sm {
	margin-bottom:40px;
}

.o-mg-btm-md {
	margin-bottom: 60px;
}

.o-mg-btm-lg {
	margin-bottom:120px;
}

//Animations
.js-fade-in-left {
	opacity: 0;
	visibility: hidden;
	transform: translate(-100px, 0px); 
  	transition: all 0.5s ease-out 0s;
}

.js-fade-in-right {
	opacity: 0;
	visibility: hidden;
	transform: translate(20px, 0px); 
  	transition: all 0.5s ease-out 0s;
}

.js-fade-in-down {
	opacity: 0;
	visibility: hidden;
	transform: translate(0px, -20px); 
  	transition: all 0.5s ease-out 0s;
}


.js-fade-in-up {
	opacity: 0;
	visibility: hidden;
	transform: translate(0px, 20px); 
  	transition: opacity 0.5s ease-out 0s, transform 0.5s ease-out 0s, visibility 0.5s ease-out 0s;
}

.js-fade-in {
	opacity: 0;
	transition: opacity 0.5s ease-out 0s, transform 0.5s ease-out 0s, visibility 0.5s ease-out 0s;
}

.o-delay-lg {
	transition-delay:1.6s;
}

.o-delay-md {
	transition-delay:1.2s;
}

.o-delay-sm {
	transition-delay:0.8s;
}

.o-delay-xs {
	transition-delay:0.3s;
}

.o-delay-ss {
	transition-delay:0.1s;
}


.o-fade-in-up {
	visibility: visible;
  	opacity: 1;
  	transform: translate(0px, 0px); 
}

.o-fade-in-down {
	visibility: visible;
  	opacity: 1;
  	transform: translate(0px, 0px); 
}

.o-fade-in {
	visibility: visible;
  	opacity: 1;
}

@keyframes fade-in {
  0% { 
      opacity: 0; 
      visibility: hidden;
      transform: translate(0px, 20px) 
  }

  50% {
    visibility: visible;
    opacity: 0;
  }

    100% { 
      opacity: 1; 
      visibility: visible;
      transform: translate(0px, 0px) 
    }
}