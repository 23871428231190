@import url("//hello.myfonts.net/count/3da2d7");
  
@font-face {
  font-family: "CeraPro-Regular";
  src: url('../resources/fonts/CeraProLight/font.woff2') format('woff2'), url('../resources/fonts/CeraProLight/font.woff') format('woff');
}
@font-face {
  font-family: "CeraPro-Regular";
  src: url('../resources/fonts/CeraProRegular/font.woff2') format('woff2'), url('../resources/fonts/CeraProRegular/font.woff') format('woff');
}
@font-face {
  font-family: "CeraPRO-Bold";
  src: url('../resources/fonts/CeraPROBold/font.woff2') format('woff2'), url('../resources/fonts/CeraPROBold/font.woff') format('woff');
}



@font-face
{
    font-family: "Flecha M Regular";
    src: url('../resources/fonts/FlechaM-Regular.woff2') format('woff2'), url('../resources/fonts/FlechaM-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.font-237 {font-family:"Flecha M Regular"}


@import "headings";

@import "copy";
