.carousel {
  &__item {
    padding: 0 20px;

    @include media-breakpoint-down(md) {
      padding: 0 10px;
    }

    img {
      border-radius: 10px;
      @include media-breakpoint-down(md) {
        border-radius: 5px;
      }
    }
  }

  .slick-slider-dots {
    width: 100%;
    text-align: center;
    margin: 0 auto;

    .slick-dots {
      margin: 0;
      padding: 20px 0 0;
      list-style: none;
      text-align: center;

      li {
        display: inline-block;

        button {
          padding: 0;
          margin: 0 4px;
          height: 12px;
          width: 12px;
          display: block;
          display: block;
          border-radius: 50px;
          text-indent: -99999px;
          background: rgba(255,255,255,0.5);
          -webkit-appearance: none;
          border: none;
          box-shadonw: none;
          transition-duration: 0.3s;
          transition-property: all;

          @include media-breakpoint-down(md) {
            height: 10px;
            width: 10px;
          }

          @include media-breakpoint-down(sm) {
            height: 8px;
            width: 8px;
            margin: 0 2px;
          }
        }

        &.slick-active {
          button {
            background: rgba(255,255,255,1);
          }
        }
      }
    }
  }
}