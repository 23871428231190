#hero {

  &__title {
    max-width: 1120px;
    width:80%;
    z-index: 2;

    h1 {
      font-size: calc(29px + (80 - 29) * ((100vw - 300px) / (1900 - 300)));
    }
  }

  &__video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    max-width: initial;
    object-position: center;

    @include media-breakpoint-down(sm) {
      max-width: none;
      width: 100%;
      height: 100%;
    }
  }

  &__scroll {
    width:1px;
    height:100px;
    background:rgba(255,255,255,0.4);
    bottom:40px;
    z-index:100;
    transition: opacity 1s ease-out 0s;
    transition-delay: 2.5s;

    &:after {
      content:'';
      width:1px;
      height:30px;
      background:rgba(255,255,255,1);
      left:0;
      right:0;
      margin:0 auto;
      display:block;
      position:absolute;
      opacity: 1;

      animation: MoveUpDown 1.5s linear infinite;
      transition: opacity 1s ease-out 0s;
      transition-delay: 2.5s;
    }
  }
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 70px;
  }
  50% {
    bottom: 0px;
  }
}