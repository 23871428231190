.panel {
  width: 58.35vw;
  margin: 0 auto 7vw;
  transform: scale(1);
  transition-duration: 0.3s;
  transition-property: all;

  @include media-breakpoint-down(xl) {
    width: 60vw;
  }

  @include media-breakpoint-down(lg) {
    width: 90%;
  }


  @include media-breakpoint-down(sm) {
    margin: 0 auto 15vw;
  }

  &:hover {
    transform: scale(1.075);
  }
  
  &__inner {
    height: 35vw;

    @include media-breakpoint-down(xl) {
      height: 40vw;
    }

    @include media-breakpoint-down(lg) {
      height: 500px;
    }

    @include media-breakpoint-down(sm) {
      height: 60vw;
    }
  }

  &__video,
  img  {
    min-width: 100%;
    min-height: 35vw;
    height: 35vw;
    max-width: initial;

    @include media-breakpoint-down(xl) {
      height: 40vw;
    }

    @include media-breakpoint-down(lg) {
      height: 500px;
    }

    @include media-breakpoint-down(sm) {
      height: 60vw;
    }
 }

  &__details {
    top: 30px;
    left: 30px;
    color: $shaft;
    z-index:3;
    position: absolute;

    @include media-breakpoint-down(sm) {
      top: auto;
      position: relative;
      padding: 20px 0 0;
      left: 25px;
      transition-delay: 0s !important;
    }

    p {
      font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1900 - 300)));
      line-height: 1.0545;
      margin-top: 5px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

}

#pocko-item {
  h2,
  p {
    color: $shaft;
  }

  @include media-breakpoint-down(sm) {
    h2,
    p {
      color: white;
    }
  }

}

#panels {
  &__title {
    max-width: 90%;
    position: sticky;

    @include media-breakpoint-down(md) {
      position: relative;
      height: 60vh !important;
      margin-top: -100px !important;
    }
  }
}
