/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
@import "links";

#top-wrapper {
	padding: 30px;
	z-index: 900;
	//backdrop-filter: blur(20px);
	transition-duration: 0.3s;
    transition-property: all;

    @include media-breakpoint-down(sm) {
      padding: 30px 0;
    }

	&.fixed {
		padding: 25px 30px;

		@include media-breakpoint-down(sm) {
	      padding: 25px 0;
	    }

		#logo {
			font-size: 2rem;
		}

		.btn-std {
			font-size: 1.5rem;
			padding: 8px 20px;
		}
	}
}

#logo {
	font-size: calc(20px + (27 - 20) * ((100vw - 300px) / (1900 - 300)));
}

.home {
	#logo {
		color: white;
	}
}

/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
@import "footer";