.cookie-banner {
    padding: 40px;
    position: fixed;
    z-index: 600;
    left: 0;
    bottom: 0;
    width: 100%;

    &__inner {
        background: white;
        padding: 20px;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 400;

        p {
            display: inline-block;
        }
    }

    .btn-std {
        width:100%;
    }

    p {
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
    }


    &.hide {
        display: none;
    }
}

#cookie-info {
    display:inline-block;
}