h1, h2, h3, h4, h5, h6 {
	clear: both;
}

h1 {
	font-family: "Recoleta";
	font-size: calc(24px + (80 - 24) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1.2545;
	color: white;
	margin: 0;
	font-weight: normal;
	opacity: 0;
}

h2 {
	font-family: "Recoleta";
	font-size: calc(19px + (30 - 19) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1.2545;
	color: white;
	margin: 0;
	font-weight: normal;
}

h3 {
	font-family: "Recoleta";
	font-size: calc(40px + (200 - 40) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1;
	color: white;
	margin: 0;
	padding: 0;
	font-weight: normal;
	text-align: center;
}

h4 {
	font-family: "Recoleta";
	font-size: calc(20px + (30 - 20) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1;
	color: white;
	margin: 0 0 20px;
	padding: 0;
	font-weight: normal;
	text-align: center;
}

h5 {
	font-size: calc(50px + (100 - 50) * ((100vw - 300px) / (1900 - 300)));
	line-height: 1;
	margin:0;
	padding:0;

	a {
	  color: white;

	  &:visited {
	    color: white;
	  }

	  &:hover,
	  &:active {
	  	color: white;
	    border-color: white;
	  }
	}
}