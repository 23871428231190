//Colours
$vivid:		#FF977E;
$shaft:		#171717;
$blue:		#09158B;
$deep:		#008ba1;
$congress:	#004B8D;
$end:		#0067B3;
$martini:	#2d274d;
$haze:		#00ab4e;
$pink:		#FF6A97;
