#footer-wrapper {

	@include media-breakpoint-down(sm) {
        text-align: center;
      }

	p {
		font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1900 - 300)));
		line-height:2.2rem;
	}

	span {
		font-family: Arial;
	}
}
